import React, { useRef, useState, useCallback, useEffect } from 'react';
import { useStyles } from '../../hooks';
import { Tooltip } from '@material-ui/core';
import { CustomEmissionMarker } from '../CustomEmissionMarker';

import logo from '../../assets/images/icon-little.png';

import styles from './jss/CustomRacetrack';

const FunctionComponent = (props) => {
  const {
    svg,
    svgWidth,
    svgHeight,
    topLat,
    bottomLat,
    rightLng,
    leftLng,
    markerConfiguration,
    display = 'emission',
    counter = null,
    points = [],
    renderCount,
  } = props;
  const classes = useStyles(styles, props);

  const divRef = useRef(null);
  const svgRatioRef = useRef(svgHeight / svgWidth);
  const rangeLatRef = useRef(Math.abs(topLat - bottomLat));
  const rangeLngRef = useRef(Math.abs(rightLng - leftLng));

  const [alertCounter, setAlertCounter] = useState(null);
  const [, setDivDimensions] = useState({
    offsetWidth: 0,
    offsetHeigth: 0,
  });

  const getMarker = useCallback(
    (point) => {
      if (!divRef.current) return;

      if (
        point.latitude > topLat ||
        point.latitude < bottomLat ||
        point.longitude > rightLng ||
        point.longitude < leftLng
      )
        return (
          <div
            key={point.dateTime}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              opacity: 0,
            }}
          />
        );

      const { offsetWidth, offsetHeight } = divRef.current;
      const divRatio = offsetHeight / offsetWidth;
      const diffLat = Math.abs(topLat - point.latitude);
      const diffLng = Math.abs(leftLng - point.longitude);
      let top;
      let left;
      let diameterFactor = offsetWidth / markerConfiguration.diameterConstant;

      if (svgRatioRef.current > divRatio) {
        const actualOffsetWidth = offsetHeight / svgRatioRef.current;
        const offset = (offsetWidth - actualOffsetWidth) / 2;

        top = (diffLat * offsetHeight) / rangeLatRef.current;
        left = (diffLng * actualOffsetWidth) / rangeLngRef.current + offset;
        diameterFactor = actualOffsetWidth / markerConfiguration.diameterConstant;
      } else if (svgRatioRef.current < divRatio) {
        const actualOffsetHeight = offsetWidth * svgRatioRef.current;
        const offset = (offsetHeight - actualOffsetHeight) / 2;

        top = (diffLat * actualOffsetHeight) / rangeLatRef.current + offset;
        left = (diffLng * offsetWidth) / rangeLngRef.current;
      } else {
        top = (diffLat * offsetHeight) / rangeLatRef.current;
        left = (diffLng * offsetWidth) / rangeLngRef.current;
      }

      switch (display) {
        case 'vehicle':
        case 'emission':
          return (
            <CustomEmissionMarker
              key={point.dateTime}
              top={top}
              left={left}
              diameterFactor={diameterFactor}
              dateTime={new Date(point.dateTime)}
              batteryVoltage={point.batteryVoltage}
              emission={point.emission}
              alert={point.alert}
              ignitionOff={point.ignitionOff}
              closerToTop={diffLat < Math.abs(bottomLat - point.latitude)}
              closerToLeft={diffLng < Math.abs(rightLng - point.longitude)}
              emissionRange={markerConfiguration.emissionRange}
              diameterRange={markerConfiguration.diameterRange}
              rgbRange={markerConfiguration.rgbRange}
              opacity={markerConfiguration.opacity}
              opacityHover={markerConfiguration.opacityHover}
              isPaused={point.isPaused}
            />
          );
        default:
          return (
            <div
              key={point.dateTime}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                opacity: 0,
              }}
            />
          );
      }
    },
    [svg]
  );

  useEffect(() => {
    if (divRef.current) {
      setDivDimensions({
        offsetWidth: divRef.current.offsetWidth,
        offsetHeigth: divRef.current.offsetHeight,
      });
    }
  }, [renderCount]);

  useEffect(() => {
    svgRatioRef.current = svgHeight / svgWidth;
    rangeLatRef.current = Math.abs(topLat - bottomLat);
    rangeLngRef.current = Math.abs(rightLng - leftLng);
  }, [svg]);

  useEffect(() => {
    setAlertCounter(points.filter(({ alert }) => alert).length);
  }, [points]);

  return (
    <div className={classes.root}>
      <div
        ref={divRef}
        style={{
          backgroundImage: `url("${svg}")`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
        }}
        className={classes.container}
      >
        <img
          style={{
            position: 'absolute',
            ...markerConfiguration.logoStyle,
          }}
          src={logo}
          alt="chiptronic"
        />
        {points.map(getMarker)}
        {/* {points.filter(({ alert, ignitionOff }) => alert && !ignitionOff).map(getMarker)}
        {points.filter(({ alert, ignitionOff }) => !alert && ignitionOff).map(getMarker)} */}
        {counter !== null ? (
          <Tooltip
            classes={{ tooltip: classes.counterTooltip }}
            title={markerConfiguration.counterTooltip}
            placement={markerConfiguration.counterTooltipPosition}
          >
            <div
              style={{
                position: 'absolute',
                ...markerConfiguration.counterPosition,
                opacity: markerConfiguration.opacity,
              }}
              className={classes.counter}
            >
              {counter}
            </div>
          </Tooltip>
        ) : null}
        {alertCounter ? (
          <Tooltip
            classes={{ tooltip: classes.alertCounterTooltip }}
            title={markerConfiguration.alertCounterTooltip}
            placement={markerConfiguration.alertCounterTooltipPosition}
          >
            <div
              style={{
                position: 'absolute',
                ...markerConfiguration.alertCounterPosition,
                opacity: markerConfiguration.opacity,
              }}
              className={classes.alertCounter}
            >
              {alertCounter}
            </div>
          </Tooltip>
        ) : null}
      </div>
    </div>
  );
};

export const CustomRacetrack = FunctionComponent;

export default CustomRacetrack;
